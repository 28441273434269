<div class="grid place-items-center content-center h-full">

    <form [formGroup]="loginForm" class="max-w-xs">

        <mat-form-field class="w-full">

            <mat-label>Benutzername</mat-label>

            <input matInput type="text" 
                   formControlName="username"
                   name="username" 
                   tabindex="1" />

            <mat-icon *ngIf="loginForm.get('username')?.value"
                      matSuffix
                      (click)="loginForm.get('username')?.reset()"
                      class="cursor-pointer">
                close
            </mat-icon>

        </mat-form-field>

        <mat-form-field class="w-full">

            <mat-label>Passwort</mat-label>
            
            <input matInput type="password" 
                   formControlName="password"
                   type="{{hideVisibility ? 'password' : 'text'}}" 
                   name="password" 
                   tabindex="2" />

            <mat-icon *ngIf="loginForm.get('password')?.value" 
                      matSuffix
                      (click)="loginForm.get('password')?.reset()"
                      class="cursor-pointer !pr-0">
                close
            </mat-icon>

            <mat-icon matSuffix 
                      (click)="hideVisibility = !hideVisibility" 
                      class="cursor-pointer">
                {{hideVisibility ? 'visibility_off' : 'visibility'}}
            </mat-icon>

            <mat-hint><a [routerLink]="'/password-reset'">Passwort vergessen?</a></mat-hint>

        </mat-form-field>

        <button type="submit" 
                name="submit" 
                mat-flat-button 
                color="primary" 
                class="w-full !h-14 mt-3" 
                tabindex="3" 
                ngHcaptchaInvisibleButton
                (verify)="onVerify($event)">
            Anmelden
        </button>

    </form>

    <section class="w-full max-w-xs mt-3">
        
        <label>Noch kein Konto?</label>
        <button type="button"
                name="register"
                [routerLink]="'/register'"
                mat-flat-button
                color="accent"
                class="w-full !h-14"
                tabindex="4">
                Registrieren
        </button>

    </section>

</div>