import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HcaptchaService {

	constructor(
		private http: HttpClient
	) { }


	siteverify(token: string): Observable<any> {

		let headers = new HttpHeaders()
		.set('Content-Type', 'application/x-www-form-urlencoded')

		let body = new HttpParams()
		.set('response', token)
		.set('secret', '0xac78Ab681242b5C3739D6bc0D654867696ad0037')

		return this.http.post<any>('https://hcaptcha.com/siteverify', body.toString(), { headers: headers })
	}
}
