import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientXsrfModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgHcaptchaModule } from 'ng-hcaptcha';

import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { AdminModule } from './admin/admin.module';

import { LoginComponent } from './login/login.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { RegisterComponent } from './register/register.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

import * as Sentry from "@sentry/angular-ivy";
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';


@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		PasswordResetComponent,
		RegisterComponent,
		ChangePasswordComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		CoreModule,
		SharedModule,
		AdminModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		NgHcaptchaModule.forRoot({
			siteKey: environment.hCaptchaSiteKey,
			languageCode: 'de' // optional, will default to browser language
		}),
		HttpClientXsrfModule.withOptions({
			cookieName: 'XSRF-TOKEN',
			headerName: 'X-XSRF-TOKEN',
		}),
	],
	providers: [{
		provide: ErrorHandler,
		useValue: Sentry.createErrorHandler({
			showDialog: true,
		}),
	}, {
		provide: Sentry.TraceService,
		deps: [Router],
	},
	{
		provide: APP_INITIALIZER,
		useFactory: () => () => { },
		deps: [Sentry.TraceService],
		multi: true,
	},
	{ 
		provide: LocationStrategy, 
		useClass: PathLocationStrategy 
	}],
	bootstrap: [AppComponent]
})
export class AppModule { }
