import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HcaptchaService } from '@core/services/hcaptcha.service';
import { Login } from '@core/states/auth/auth.action';
import { SetUser } from '@core/states/user/user.action';
import { Store } from '@ngxs/store';
import { switchMap } from 'rxjs';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	loginForm: FormGroup
	hideVisibility: boolean = true;
	isVerificated: boolean = false;

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private snackBar: MatSnackBar,
		private store: Store,
		private hCaptcha: HcaptchaService
	) { }

	ngOnInit(): void {
		
		// this.loginForm = new FormGroup({});

		this.loginForm = this.formBuilder.group({
			username: new FormControl('', [Validators.required]),
			password: new FormControl('', [Validators.required]),
			captcha: new FormControl()
		})
		
	}

	login(): void {

		this.snackBar.open('Anmelden...', '', { duration: 2000, panelClass: ['info-snack'] });

		this.store.dispatch(new Login({
			username: this.loginForm.get('username')?.value,
			password: this.loginForm.get('password')?.value,
		}))
		.pipe(
			switchMap(() => {
				return this.store.dispatch(new SetUser());
			}
		))
		.subscribe(
			() => {
				this.snackBar.open('Authentifizierung erfolgreich.', '🛰️', { duration: 2000, panelClass: ['success-snack'] });
				const redirect = this.activatedRoute.snapshot.queryParams['redirect_to'] || `admin/home`;
				this.router.navigate([redirect]);
			}
		)
	}

	onVerify(token: string): void {
		// TODO: Check why still CORS errors
		/*this.hCaptcha.siteverify(token).subscribe((data: any) => {
			console.log('hcaptcha', data)
		})*/
		// For now log in without site verification
		this.login()
	}
	
}
