import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@core/services/auth.service';

@Component({
	selector: 'app-password-reset',
	templateUrl: './password-reset.component.html',
	styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent {
	
	resetForm: FormGroup
	success: boolean = false;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService
	) { }

	ngOnInit(): void {

		// this.resetForm = new FormGroup({});

		this.resetForm = this.formBuilder.group({
			username: new FormControl('', [Validators.required]),
			captcha: new FormControl(null, [Validators.required])
		})
	}

	onSubmit(): void {
		this.authService.passwordReset(this.resetForm.get('username').value).subscribe((data: any) => {
			this.success = true;
		})
	}
}
