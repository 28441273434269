<h1 class="inline-flex items-center justify-start">
    <mat-icon class="mr-2">person</mat-icon>
    Mein Konto
</h1>

<form [formGroup]="meForm" id="meForm" (submit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
    
    <mat-card class="mb-4 p-3">

        <mat-form-field class="w-full pt-5">
            <mat-label>E-Mail Adresse</mat-label>
            <input matInput formControlName="emailAddress">
            <mat-error *ngIf="meForm.get('emailAddress').hasError('email')">
                Bitte geben Sie eine valide E-Mail Adresse ein.
            </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Benutzername</mat-label>
            <input matInput formControlName="username" required>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Passwort</mat-label>
                
            <input matInput type="password" 
                formControlName="password"
                type="{{hideVisibility ? 'password' : 'text'}}" 
                name="password" 
                tabindex="2" />
        
            <mat-icon *ngIf="meForm.get('password')?.value" 
                    matSuffix
                    (click)="meForm.get('password')?.reset()"
                    class="cursor-pointer !pr-0">
                close
            </mat-icon>
        
            <mat-icon matSuffix 
                    (click)="hideVisibility = !hideVisibility" 
                    class="cursor-pointer">
                {{hideVisibility ? 'visibility_off' : 'visibility'}}
            </mat-icon>
        </mat-form-field>
        
        <mat-form-field class="w-full">
            <mat-label>Passwort wiederholen</mat-label>
                
            <input matInput type="password" 
                formControlName="repeatPassword"
                type="{{hideVisibilityRepeat ? 'password' : 'text'}}" 
                name="repeatPassword" 
                tabindex="2" />
        
            <mat-icon *ngIf="meForm.get('repeatPassword')?.value" 
                    matSuffix
                    (click)="meForm.get('repeatPassword')?.reset()"
                    class="cursor-pointer !pr-0">
                close
            </mat-icon>
        
            <mat-icon matSuffix 
                    (click)="hideVisibilityRepeat = !hideVisibilityRepeat" 
                    class="cursor-pointer">
                {{hideVisibilityRepeat ? 'visibility_off' : 'visibility'}}
            </mat-icon>
        
            <mat-error *ngIf="meForm.get('repeatPassword')?.hasError('matchValues')">
                Die Passwörter stimmen nicht überein.
            </mat-error>
        
        </mat-form-field>
        
    </mat-card>

    <mat-card class="mb-4 p-3">

        <mat-form-field class="w-full pt-5">
            <mat-label>Anrede</mat-label>
            <mat-select formControlName="salutation">
                <mat-option *ngFor="let option of salutationOptions" [value]="option.value">{{option.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Vorname</mat-label>
            <input matInput formControlName="firstname">
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Nachname</mat-label>
            <input matInput formControlName="lastname">
        </mat-form-field>

    </mat-card>

	<mat-card class="p-3">

		<button mat-flat-button color="primary" type="submit" class="!h-14" [disabled]="meForm.invalid">
			Speichern
		</button>

	</mat-card>

</form>
